<template>
  <BCard>
    <div
        class="position-absolute mr-1 mt-1 text-dark"
        style="top: 0; right: 0"
    >
      <b-dropdown
          v-if="$store.getters['user/permission']('requests:delete')"
          variant="link"
          toggle-class="text-decoration-none p-0 toggler-grey"
          no-caret
          right
      >
        <template #button-content>
          <feather-icon
              icon="MoreVerticalIcon"
              size="24"
          />
        </template>
        <b-dropdown-item @click="showPriceModal">
          <span>
            {{ t('requests.checklist.dropdown.change-price') }}
          </span>
        </b-dropdown-item>
        <b-dropdown-item @click="showRescheduleModal">
          <span>
            {{ t('requests.checklist.dropdown.reschedule') }}
          </span>
        </b-dropdown-item>
        <b-dropdown-item @click="showCancelModal">
          <span>
            {{ t('requests.checklist.dropdown.cancel') }}
          </span>
        </b-dropdown-item>
      </b-dropdown>
    </div>
    <h4 class="mb-0">
      {{ item.name }}
    </h4>
    <div
      v-if="item.id"
      class="text-muted mb-1"
    >
      # {{ item.id }}
    </div>
    <div class="info-card">
      <Select
        :id="item.type_id"
        :value="item.type"
        entity="request"
        alias="requests"
        field="type"
        field-id="type_id"
        get-options-action="requests/types"
        icon="StarIcon"
        :return-object="!item.id"
        readonly
        @updateField="$emit('updateField', $event)"
        @updateFields="$emit('updateFields', $event)"
      />
      <Select
        :id="item.work_type_id"
        :value="item.work_type"
        entity="request"
        alias="requests"
        field="work_type"
        field-id="work_type_id"
        get-options-action="requests/workTypes"
        icon="FlagIcon"
        :return-object="!item.id"
        readonly
        @updateField="$emit('updateField', $event)"
        @updateFields="$emit('updateFields', $event)"
      />
      <Calendar
        :value="item.deadline_at"
        entity="request"
        alias="requests"
        field="deadline_at"
        icon="CalendarIcon"
        :return-object="!item.id"
        :start-weekday="1"
        readonly
        @updateField="$emit('updateField', $event)"
        @updateFields="$emit('updateFields', $event)"
      />
      <Base
        :value="item.price"
        entity="request"
        alias="requests"
        field="price"
        icon="DollarSignIcon"
        :return-object="!item.id"
        money
        readonly
        @updateField="$emit('updateField', $event)"
        @updateFields="$emit('updateFields', $event)"
      />
    </div>
    <b-modal
        ref="changePriceRef"
        v-model="changePriceModal"
        size="lg"
        :title="t('requests.checklist.dropdown.change-price')"
    >
      <template #modal-footer="{}">
        <BButton @click="$refs['changePriceRef'].hide()">
          Закрыть
        </BButton>
        <BButton
            variant="primary"
            :disabled="!priceValidate || priceLoading"
            @click="sendPriceChanges"
        >
          {{ t('requests.checklist.dropdown.change-price') }}
        </BButton>
      </template>
      <div
          class="textModal"
          style="text-align: justify"
      >
        <validation-observer ref="changePriceForm">
          <validation-provider
              #default="{ errors }"
              :name="t('requests.checklist.change-price.field')"
              rules="required|integer"
          >
            <b-form-input
                id="nameInput"
                v-model="price"
                type="number"
                :placeholder="t('requests.checklist.change-price.placeholder')"
                :state="errors.length > 0 ? false:null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </validation-observer>
        <b-form-textarea
            v-model="priceComment"
            class="mt-1"
            :placeholder="t('requests.checklist.change-price.comment')"
        />
      </div>
    </b-modal>

    <b-modal
        ref="rescheduleModalRef"
        v-model="rescheduleModal"
        size="lg"
        :title="t('requests.checklist.dropdown.reschedule')"
    >
      <template #modal-footer="{}">
        <BButton @click="$refs['rescheduleModalRef'].hide()">
          Закрыть
        </BButton>
        <BButton
            variant="primary"
            :disabled="!rescheduleValidate || rescheduleLoading"
            @click="sendRescheduleChanges"
        >
          {{ t('requests.checklist.dropdown.reschedule') }}
        </BButton>
      </template>
      <div
          class="textModal"
          style="text-align: justify"
      >
        <Calendar
            :value="calendarValue()"
            icon="CalendarIcon"
            :with-label="false"
            :start-weekday="1"
            time
            :past="false"
            :return-object="!item.id"
            @updateField="updateDate($event)"
            @updateFields="updateDate($event)"
        />
        <div class="pb-1">
          {{ t('requests.checklist.reschedule.initiator') }}
        </div>
        <div
            v-for="option in initiators"
            :key="option.id + option.name"
        >
          <div class="question-checkbox gap-1">
            <BFormRadio
                v-model="rescheduleInitiator"
                :name="String(item.id) + 'reschedule'"
                switch
                :value="option.id"
            />
            {{ option.name }}
          </div>
        </div>
        <b-form-textarea
            v-model="rescheduleComment"
            class="mt-1"
            :placeholder="t('requests.checklist.reschedule.comment')"
        />
      </div>
    </b-modal>

    <b-modal
        ref="cancelModalRef"
        v-model="cancelModal"
        size="lg"
        :title="t('requests.checklist.dropdown.cancel')"
    >
      <template #modal-footer="{}">
        <BButton @click="$refs['cancelModalRef'].hide()">
          Закрыть
        </BButton>
        <BButton
            variant="primary"
            :disabled="!cancelValidate || cancelLoading"
            @click="cancelRequest"
        >
          {{ t('requests.checklist.dropdown.cancel') }}
        </BButton>
      </template>
      <div
          class="textModal"
          style="text-align: justify"
      >
        <div class="pb-1">
          {{ t('requests.checklist.cancel.initiator') }}
        </div>
        <div
            v-for="option in initiators"
            :key="option.id + option.name"
        >
          <div class="question-checkbox gap-1">
            <BFormRadio
                v-model="cancelInitiator"
                :name="String(item.id) + 'cancel'"
                switch
                :value="option.id"
            />
            {{ option.name }}
          </div>
        </div>
        <b-form-textarea
            v-model="cancelComment"
            class="mt-1"
            :placeholder="t('requests.checklist.cancel.comment')"
        />
      </div>
    </b-modal>
  </BCard>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required, integer } from '@validations';
import {
  BCard,
  BDropdown,
  BDropdownItem,
  BModal,
  BButton,
  BFormInput,
  BFormTextarea,
  BFormRadio } from 'bootstrap-vue';
import Select from '@/components/fields/select/index.vue';
import Calendar from '@/components/fields/calendar/index.vue';
import Base from '@/components/fields/base/index.vue';
import { useI18n } from "@/hooks/useI18n";
import {computed, ref, toRefs, watch} from '@vue/composition-api';
import {useStore} from "@/hooks/useStore";
import {useToast} from "@/hooks/useToast";
import moment from "moment/moment";
import useGetParams from "@/hooks/useGetParams";

export default {
  components: {
    BCard,
    Select,
    Calendar,
    Base,
    BDropdown,
    BDropdownItem,
    BModal,
    BButton,
    BFormInput,
    BFormTextarea,
    BFormRadio,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    item: Object,
  },
  setup(props, { emit }) {
    const { t } = useI18n();
    const store = useStore();
    const { updateGetParam } = useGetParams();
    const changePriceModal = ref(false);
    const rescheduleModal = ref(false);
    const cancelModal = ref(false);

    const price = ref(null);
    const priceComment = ref(null);

    const rescheduleDate = ref(null);
    const rescheduleComment = ref(null);
    const rescheduleInitiator = ref(null);

    const cancelInitiator = ref(null);
    const cancelComment = ref(null);

    const priceLoading = ref(false);
    const rescheduleLoading = ref(false);
    const cancelLoading = ref(false);

    const priceValidate = ref(false);
    const rescheduleValidate = computed(() => rescheduleDate.value && rescheduleInitiator.value);
    const cancelValidate = computed(() => cancelInitiator.value);

    const { item } = toRefs(props);
    const { successToast, dangerToast } = useToast();
    const initiators = ref({});

    const getDictionaries = async () => {
      const result = await store.dispatch('dictionaries/getDictionaries', {
        dictionaries: 'event_initiators'
      });

      if (result) {
        initiators.value = store.state.dictionaries.list.event_initiators;
      }
    };

    getDictionaries();

    moment.locale('ru');
    const calendarValue = () => rescheduleDate.value || moment().toISOString();

    const isNumeric = (value) => /^-?\d+$/.test(value);

    watch(price, () => {
      priceValidate.value = isNumeric(price.value);
    });

    const showPriceModal = () => {
      changePriceModal.value = true;
    };
    const showRescheduleModal = () => {
      rescheduleModal.value = true;
    };
    const showCancelModal = () => {
      cancelModal.value = true;
    };

    const updateDate = (date) => {
      rescheduleDate.value = date.value;
    };

    const sendPriceChanges = async () => {
      priceLoading.value = true;
      const { result } = await store.dispatch('requests/changePrice', {
        price: price.value,
        comment: priceComment.value,
        id: item.value.id
      });

      changePriceModal.value = false;
      priceComment.value = null;

      if (result) {
        successToast(t('requests.checklist.change-price.message.success'));
        item.value.price = price.value;
        setTimeout(() => {
          price.value = null;
        }, 1000);
        priceLoading.value = false;
        return;
      }

      dangerToast(t('requests.checklist.change-price.message.error'));
      priceLoading.value = false;
      setTimeout(() => {
        price.value = null;
      }, 1000);
    };

    const sendRescheduleChanges = async () => {
      rescheduleLoading.value = true;
      const { result } = await store.dispatch('requests/reschedule', {
        id: item.value.id,
        comment: rescheduleComment.value,
        initiator: rescheduleInitiator.value,
        date: rescheduleDate,
      });

      rescheduleModal.value = false;
      rescheduleInitiator.value = null;
      rescheduleComment.value = null;

      if (result) {
        successToast(t('requests.checklist.reschedule.message.success'));
        item.value.deadline_at = rescheduleDate.value;
        rescheduleDate.value = null;
        rescheduleLoading.value = false;
        return;
      }

      rescheduleDate.value = null;
      rescheduleLoading.value = false;
      dangerToast(t('requests.checklist.reschedule.message.error'));
    };

    const cancelRequest = async () => {
      cancelLoading.value = true;
      const { data, result } = await store.dispatch('requests/cancel', {
        id: item.value.id,
        comment: cancelComment.value,
        initiator: cancelInitiator.value,
      });

      cancelModal.value = false;
      cancelInitiator.value = null;
      cancelComment.value = null;

      if (result) {
        successToast(t('requests.checklist.cancel.message.success'));
        cancelLoading.value = false;
        updateGetParam('openedChecklist', null);
        emit('updateStatus', data.status);
        return;
      }

      cancelLoading.value = false;
      dangerToast(t('requests.checklist.cancel.message.error'));
    };

    return {
      t,
      initiators,
      item,

      updateDate,

      showPriceModal,
      changePriceModal,
      rescheduleModal,
      showRescheduleModal,
      cancelModal,
      showCancelModal,

      required,
      integer,
      priceValidate,
      rescheduleValidate,
      cancelValidate,

      price,
      priceComment,

      priceLoading,
      rescheduleLoading,
      cancelLoading,

      rescheduleDate,
      rescheduleComment,
      rescheduleInitiator,
      calendarValue,

      cancelInitiator,
      cancelComment,

      sendPriceChanges,
      sendRescheduleChanges,
      cancelRequest,
    }
  },
};
</script>

<template>
  <div class="field field-select">
    <div class="d-flex align-items-center mr-2">
      <feather-icon
        v-if="icon"
        :icon="icon"
        class="mr-75"
      />
      <span
        v-if="!noLabel"
        class="font-weight-bold"
      >{{ t(`${alias}.field.${field}.label`) }}</span>
    </div>
    <div v-if="!autocomplete">
      <EditInput
        :model="value"
        :toggle-only-by-icon="false"
        type="dropdown"
        class="m-0"
        :options="options"
        :readonly="readonly"
        :dropdown-class="dropdownClass"
        @input="update"
      >
        <span class="card-text editable-field">
          <template v-if="value && value.name">
            {{ formatName(value) }}
          </template>
          <template v-else-if="id">
            {{ options.find((i) => i.id === id).name }}
          </template>
          <template v-else>
            {{ t(`${alias}.field.${field}.placeholder`) }}
          </template>
        </span>
      </EditInput>
    </div>
    <div v-else>
      <EditInput
        :with-select="true"
        :toggle-only-by-icon="false"
        :hide-edit-icon="true"
        :only-exists="true"
        :options="options"
        :readonly="readonly"
        @input="update"
      >
        <span class="card-text editable-field">
          <template v-if="value && (value.name || value.firstname)">
            {{ formatName(value) }}
          </template>
          <template v-else-if="id && options && options.find((i) => i.id === id)">
            {{ options.find((i) => i.id === id).name }}
          </template>
          <template v-else>
            {{ t(`${alias}.field.${field}.placeholder`) }}
          </template>
        </span>
      </EditInput>
    </div>
  </div>
</template>

<script>
import { ref, toRefs } from '@vue/composition-api';
import { useStore } from '@/hooks/useStore';
import { useI18n } from '@/hooks/useI18n';
import EditInput from '@/components/editInput/EditInput.vue';

export default {
  components: { EditInput },
  props: {
    icon: String,
    value: Object,
    id: Number,
    entity: String,
    alias: String,
    field: String,
    fieldId: String,
    getOptionsAction: String,
    autocomplete: Boolean,
    returnObject: Boolean,
    readonly: Boolean,
    noLabel: Boolean,
    dropdownClass: String,
    excludeIds: Array,
  },
  setup(props, { emit }) {
    const store = useStore();
    const { t } = useI18n();
    const {
      getOptionsAction, fieldId, field, returnObject, excludeIds,
    } = toRefs(props);

    // eslint-disable-next-line arrow-body-style
    const formatName = (object) => {
      return object.name ? object.name : `${object.firstname || ''} ${object.surname || ''}`;
    };

    const options = ref();
    const rawOptions = ref([]);
    // eslint-disable-next-line no-return-await
    const getOptions = async () => await store.dispatch(getOptionsAction.value);

    (async () => {
      // eslint-disable-next-line max-len
      rawOptions.value = (await getOptions())?.data || [];
      options.value = rawOptions.value.map((i) => ({ label: formatName(i), value: i.id })) || [];
        if (excludeIds.value !== undefined) {
            options.value = options.value.filter((i) => !excludeIds.value.includes(i.value));
        }
    })();

    const update = (id) => {
      if (returnObject.value) {
        const model = {};
        model[fieldId.value] = id;
        model[field.value] = rawOptions.value.find((i) => i.id === id);
        emit('updateFields', { model });
      } else {
        emit('updateField', { field: fieldId.value, value: id, update: true });
      }
    };

    return {
      t,
      options,
      formatName,
      update,
    };
  },
};
</script>
